.studio-menu {
  cursor: pointer;
  position: fixed;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  z-index: 9000;
}
.studio-menu-item {
  cursor: pointer;
  padding: 5px;
  height: 28px;
  border-bottom: 1px solid rgb(187, 187, 187);
}
.studio-menu-item:hover {
  color: #2980b9;
}
